<template>
  <div>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="influencers"
      hide-default-footer
      :items-per-page="50"
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      ></v-progress-linear>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.ic_uuid">
            <td>
              <v-avatar>
                <img :src="item.ic_profile_image_url" />
              </v-avatar>
            </td>
            <td>
              <router-link
                :to="{
                  name: 'influencer',
                  params: { id: item.uuid }
                }"
              >
                {{ item.user.first_name }}
                {{ item.user.last_name }}
              </router-link>
              <br />
              {{ item.gender === "MALE" ? "Mand" : "Kvinde" }} /
              {{ item.user.influencer.birthday | date_to_age }}
            </td>
            <td>{{ item.ch_display_name }}</td>
            <td>
              <a
                :href="'https://www.instagram.com/' + item.ic_username"
                target="_blank"
              >
                {{ item.ic_username }}
              </a>
            </td>
            <td>{{ item.ic_followers }}</td>
            <td>{{ item.ic_media_count }}</td>
            <td class="text-right">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="primary"
                    class="mr-2"
                    outlined
                    icon
                    @click="
                      setselectedInfluencerChannel(item);

                      statsDialog = true;
                    "
                  >
                    <v-icon small>fal fa-chart-line</v-icon>
                  </v-btn>
                </template>
                <span>Se følger data</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="success"
                    class="mr-2"
                    outlined
                    icon
                    @click="
                      selectedInfluencerChannel = item;
                      approveDialog = true;
                    "
                  >
                    <v-icon color="success" small>fal fa-check</v-icon>
                  </v-btn>
                </template>
                <span>Godkend kanal</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="error"
                    outlined
                    icon
                    @click="
                      selectedInfluencerChannel = item;
                      declineDialog = true;
                    "
                  >
                    <v-icon color="error" small>fal fa-times</v-icon>
                  </v-btn>
                </template>
                <span>Afvis kanal</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-pagination
      v-model="page"
      class="my-2"
      :length="total"
      :total-visible="7"
      @input="handlePageChange"
    ></v-pagination>
    <influencer-channel-data-dialog
      v-if="statsDialog"
      v-model="selectedInfluencerChannel"
    ></influencer-channel-data-dialog>

    <confirm-dialog
      v-model="approveDialog"
      title="Godkend kanal"
      text="Er du sikker på at kanalen skal godkendes?"
      btn-text="GODKEND"
      color="success"
      @confirm="approveChannel()"
    ></confirm-dialog>

    <v-dialog max-width="500" v-model="declineDialog">
      <v-card>
        <v-card-title>
          <span class="headline">Afvis kanal</span>
        </v-card-title>
        <v-card-text>
          Er du sikker på at kanalen skal afvises?

          <v-select
            v-model="declineReason"
            :items="declineReasons"
            label="Hvorfor bliver kanalen afvist?"
          />
          <v-text-field
            v-if="declineReason === 'Andet'"
            v-model="declineReasonOther"
            label="Beskriv hvorfor kanalen skal afvises"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel()">Annuller</v-btn>
          <v-btn color="error" @click="declineChannel()">AFVIS</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import date_to_age from "@/helpers/filters/date_to_age";
import InfluencerChannelDataDialog from "../dialogs/InfluencerChannelDataDialog";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import { mapActions } from "vuex";

export default {
  components: {
    ConfirmDialog,
    InfluencerChannelDataDialog
  },
  filters: {
    local_numbers,
    utc_to_local_datetime,
    date_to_age
  },
  data: () => ({
    influencers: [],
    headers: [
      { text: "", sortable: false },
      { text: "Navn", sortable: false },
      { text: "Kanal", sortable: false },
      { text: "Brugernavn", sortable: false },
      { text: "Følgere", sortable: false },
      { text: "Opslag", sortable: false },
      { text: "", sortable: false }
    ],
    declineReasons: [
      "Ikke nok følgere",
      "Ikke nok danske følgere",
      "For få eksponeringer eller for kort rækkevidde",
      "Andet"
    ],
    declineReason: null,
    declineReasonOther: null,
    selectedInfluencerChannel: null,
    statsDialog: false,
    approveDialog: false,
    declineDialog: false,
    page: 1,
    limit: 15,
    total: null
  }),
  computed: {
    avatarSize() {
      if (this.$vuetify.breakpoint.sm) {
        return 150;
      } else if (this.$vuetify.breakpoint.xs) {
        return 100;
      }
      return 200;
    }
  },
  watch: {
    selectedInfluencerChannel() {
      if (this.selectedInfluencerChannel === null) {
        this.statsDialog = false;
        this.approveDialog = false;
        this.declineDialog = false;
      }
    },
    declineReason(newVal) {
      if (newVal !== "Andet") {
        this.declineReasonOther = null;
      }
    }
  },
  methods: {
    ...mapActions("moderator", [
      "getModeratorInfluencersChannels",
      "setChannelApplication"
    ]),
    handlePageChange(value) {
      this.fetchInfluencerChannels(value);
      this.scrollToTop();
    },
    fetchInfluencerChannels(page = 1) {
      this.page = page;
      const params = {
        limit: 50,
        page: this.page,
        orderBy: "created_at",
        order: "desc",
        approved: false,
        declined: false,
        active: true
      };
      this.getModeratorInfluencersChannels(params).then(influencers => {
        this.influencers = influencers.response;
        this.total = influencers.lastPage
      });
    },
    approveChannel() {
      const params = {
        influencer_channel_uuid: this.selectedInfluencerChannel.ic_uuid,
        approved: true
      };
      this.setChannelApplication(params).then(
        () => {
          this.setSnackSuccess("Godkendt");
          this.selectedInfluencerChannel = null;
          this.fetchInfluencerChannels();
        },
        () => {
          this.setSnackError("Fejl");
        }
      );
    },
    setselectedInfluencerChannel(item) {
      this.selectedInfluencerChannel = {
        active: item.ic_active,
        approved_at: item.ic_approved_at,
        followers: item.ic_followers,
        impressions: item.ic_impressions,
        media_count: item.ic_media_count,
        channel: {
          icon: item.ch_icon
        },
        profile_image_url: item.ic_profile_image_url,
        username: item.ic_username,
        uuid: item.ic_uuid
      }
    },
    declineChannel() {
      const params = {
        influencer_channel_uuid: this.selectedInfluencerChannel.ic_uuid,
        approved: false,
        decline_reason: this.declineReasonOther
          ? this.declineReasonOther
          : this.declineReason
      };
      this.setChannelApplication(params).then(
        () => {
          this.setSnackSuccess("Afvist");
          this.selectedInfluencerChannel = null;
          this.declineReason = null;
          this.fetchInfluencerChannels();
        },
        () => {
          this.setSnackError("Fejl, husk at udfylde en begrundelse");
        }
      );
    }
  },
  created() {
    this.fetchInfluencerChannels();
  }
};
</script>
